<template>
  <div class="home">
    <div class="Pricing">
      <section class="plan1">
        <div class="inner">
          <div class="left">
            <div class="img">
              <img src="@/assets/images/review3.png" alt="" />
            </div>
            <div class="flex">
              <span>Plan I</span>
              <h2>인공지능 리뷰자동분석 SW 서비스</h2>
              <div>
                <p>리뷰마인드의 SW 서비스는 충전 방식으로 제공됩니다.</p>
                <span>
                  - 일정 금액 충전 후
                  <em>(분석 리뷰 개수) * (건당 100원)</em>으로 총 금액을
                  산출하여 차감해 나가는 방식
                </span>
              </div>
            </div>
          </div>
          <div class="right flex">
            <div class="img">
              <img src="@/assets/images/review3.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="plan2">
        <div class="inner">
          <div class="left"></div>
          <div class="right">
            <div class="flex">
              <span>Plan II</span>
              <h2>{{ $t("pricing_consulting") }}</h2>
              <ul class="txt">
                <li>
                  <span>보다 Customized 된 서비스를 원하시는 고객</span>을 위한
                  숙련된 <span>리뷰마인드만의 고품질 컨설팅 서비스</span>를
                  제공합니다.
                </li>
                <li>
                  <span>
                    NLP 전문 데이터 사이언스 및 AI/NLP 모델 알고리즘 개발 관련
                    전문적인 컨설팅 </span
                  >을 제공합니다.
                </li>
                <li>
                  <span>NLP용 AI 모델 및 시스템 개발의 모든 것</span>,
                  리뷰마인드에 문의하세요. 전문상담사가 친절하게 응대해
                  드립니다!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="service">
        <div class="inner">
          <div>
            <h2>{{ $t("demo_request") }}</h2>
            <p>
              * 리뷰마인드는 2022년 <span>AI 바우처</span> 지원사업 및
              <span>데이터 바우처</span> 지원사업 공급기업에 선정되어, 해당
              사업을 통한 서비스 진행도 가능합니다.
            </p>
            <a href="tel:02-713-5847"> 서비스 문의하기 </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import {
  createCheckout,
  getCheckoutComplete,
  deleteCheckout,
} from "@/api/index";
import { mapState } from "vuex";

export default {
  data() {
    return {
      IMP: "",
    };
  },
  created() {
    this.$store.dispatch("GNB_MENU", 6);
  },
  computed: {
    ...mapState(["userInfo", "isPaid", "isLogin"]),
  },
  methods: {
    handleCharge(type, price) {
      if (this.isLogin == false) {
        alert("로그인 후 이용해주세요");
        this.$router.push("/login");
      }
      if (this.isPaid) {
        return alert("이미 구독중인 상품이 있습니다.");
      }
      let data = {
        pg: "html5_inicis",
        pay_method: "card",
        merchant_uid: "issue_billingkey_" + new Date().getTime(),
        name: "reviewMind + " + type,
        amount: price,
        buyer_email: this.userInfo.email,
        buyer_name: this.userInfo.username,
        buyer_tel: this.userInfo.mobileNo,
        buyer_addr: this.userInfo.address,
        buyer_postcode: this.userInfo.zipcode,
      };
      const IMP = window.IMP;
      IMP.init("imp60557482");
      IMP.request_pay(data, (res) => {
        data.imp_uid = res.imp_uid;

        if (res.status == "paid" || res.status == "ready") {
          createCheckout(data).then((response) => {
            if (response) {
              let params = {
                imp_uid: res.imp_uid,
              };
              getCheckoutComplete(params)
                .then((res) => {
                  if (res.status === 200) {
                    this.$router.push({ name: "paidDone" });
                  }
                })
                .catch((payErr) => {
                  window.console.error("error", payErr.response.data.message);
                })
                .finally(() => {
                  this.isBuying = false;
                });
            }
          });
        } else {
          deleteCheckout(res.imp_uid).then((response) => {
            if (response.status == 200) {
              alert(res.error_msg);
            }
          });
        }
      });
    },
  },
};
</script>
